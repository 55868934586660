import { useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Login from "./components/auth/Login";
// import Registration from "./components/auth/Registration";
import Dashboard from "./components/dashboard/menu/Dashboard";
import NotFound from "./components/NotFound";
import { useAppSelector } from "./redux/hooks";
import { getAuthData } from "./redux/slices/authSlice";
import Cookies from "js-cookie";
import Advance from "./components/dashboard/menu/Advance";
import Cost from "./components/dashboard/menu/Cost";
import Company from "./components/dashboard/menu/Company";
import Bank from "./components/dashboard/menu/Bank";
import Purpose from "./components/dashboard/menu/Purpose";
import Others from "./components/dashboard/menu/Others";
import ResetPassword from "./components/auth/resetPassword";
// import Upload from "./components/Upload";

const AppRoutes = () => {
  const authData: any = useAppSelector(getAuthData);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (Cookies.get("accessToken")) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [authData]);

  return (
    <Routes>
      {/* <Route path="upload" element={<Upload />} /> */}

      <Route element={<PublicRoute isRedirect={isAuthenticated} />}>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
      </Route>

      {/* <Route path="registration" element={<Registration />} /> */}
      <Route element={<ProtectedRoute isAllowed={isAuthenticated} />}>
        <Route path="dashboard">
          <Route index element={<Dashboard />} />
          <Route path="advance" element={<Advance />} />
          <Route path="cost" element={<Cost />} />
          <Route path="company" element={<Company />} />
          <Route path="bank" element={<Bank />} />
          <Route path="purpose" element={<Purpose />} />
          <Route path="others" element={<Others />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
  children?: React.ReactNode;
}

interface PublicRouteProps {
  isRedirect: boolean;
  children?: React.ReactNode;
}

const PublicRoute = ({ isRedirect, children }: PublicRouteProps) => {
  if (isRedirect) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children ? children : <Outlet />}</>;
};

const ProtectedRoute = ({
  isAllowed,
  redirectPath = "/",
  children,
}: ProtectedRouteProps): JSX.Element => {
  const pathname = window.location;

  console.log("pathnames", {
    pathname_0: pathname,
    pathname_1: window.location.pathname,
  });

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  if (isAllowed && window.location.pathname !== "/dashboard") {
    return <Navigate to={pathname} replace />;
  }

  return <>{children ? children : <Outlet />}</>;
};

export default AppRoutes;
