import * as React from "react";
import moment from "moment";
import * as Papa from "papaparse";
import { alpha } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
// import LinkIcon from "@mui/icons-material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import SearchAppBar from "./MainSearch";
// import MainButton from "./MainButton";
import MainModal from "./MainModal";
import { useAppDispatch } from "@/redux/hooks";
import { notifyError, notifySuccess } from "./MainNotify";
// import { deleteAdvanceAsync } from "@/redux/slices/advanceSlice";
import { CircularProgress } from "@mui/material";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  // @ts-ignore
  headCells: any[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            sx={{ color: "#fff" }}
            // color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {props.headCells?.map((headCell) => (
          <TableCell
            sx={{
              color: "#fff !important",
              svg: { color: "#fff" },
              "&:hover": {
                color: "#fff",
                svg: { color: "#fff" },
              },
              "&:active": {
                color: "#fff",
                svg: { color: "#fff" },
              },
            }}
            key={"headCell" + headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                color: "#fff !important",
                svg: { color: "#fff" },
                "&:hover": {
                  color: "#fff",
                  svg: { color: "#fff" },
                },
                "&:active": {
                  color: "#fff",
                  svg: { color: "#fff" },
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  data: any[];
  setSearch: (arr: any) => void;
  selectedIds: readonly string[];
  numSelected: number;
  setSelected: (arr: string[]) => any;
  selectedId: string;
  tableName: string;
  modalTitle: string;
  Form: () => JSX.Element;
  deleteFunc: (id: string) => any;
}

function EnhancedTableToolbar({
  data,
  setSearch,
  selectedIds,
  numSelected,
  setSelected,
  selectedId,
  tableName,
  modalTitle,
  Form,
  deleteFunc,
}: EnhancedTableToolbarProps) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  // const [clicked, setClicked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const handleDelete = async () => {
    setLoading(true);
    // const res: any = await dispatch(deleteAdvanceAsync(selectedId));
    const res: any = await dispatch(deleteFunc(selectedId));

    if (res.status === "success") {
      notifySuccess(res.message);
      setSelected([]);
      // setClicked(true);
      setLoading(false);
    } else {
      notifyError(res.message);
      setLoading(false);
    }
  };

  // const handleEdit = () => {
  //   setOpen(true);
  // };

  const handleDownloading = () => {
    const selectedData = data?.filter((row) => selectedIds.includes(row._id));

    // console.log("selectedData", selectedData);

    const csvData = selectedData.map((item) => {
      return {
        ...item,
        createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
        updatedAt: moment(item.updatedAt).format("DD-MM-YYYY"),
      };
    });

    const csvColumns = Object.keys(csvData[0]);

    const csvReport = {
      data: csvData,
      headers: csvColumns,
      filename: `${tableName}.csv`,
    };

    downloadCSV(csvReport);
  };

  const downloadCSV = (csvReport: any) => {
    const { filename, headers, data } = csvReport;
    const csv = Papa.unparse({
      fields: headers,
      data,
    });
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  React.useEffect(() => {
    if (filterText && data.length > 0) {
      const filteredData = data.filter((item) => {
        return Object.keys(item).some((key) =>
          item[key]?.toString().toLowerCase().includes(filterText.toLowerCase())
        );
      });
      setSearch(filteredData);
    } else {
      setSearch(data);
    }
  }, [filterText, data, setSearch]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        bgcolor: "#08071b",
        // borderRadius: "10px 10px 0 0",
        borderRadius: "3px 3px 0 0",
        color: "#fff",
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
          color: "#000",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableName}
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Edit" sx={{ mr: "30px" }}>
          <IconButton onClick={handleDownloading}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      )}

      {numSelected > 0 && numSelected === 1 ? (
        <>
          {/* <Tooltip title="Edit" sx={{ mr: "30px" }}>
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip> */}

          <Tooltip title="Delete">
            <IconButton onClick={handleDelete} disabled={loading}>
              {loading ? <CircularProgress /> : <DeleteIcon />}
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <SearchAppBar filterText={filterText} setFilterText={setFilterText} />
          {/* <MainButton text="Add" onClick={() => setOpen(true)} /> */}

          {numSelected === 0 && (
            <Tooltip title="Add">
              <IconButton onClick={() => setOpen(true)}>
                <AddIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          )}
          {/* <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
        </>
      )}

      <MainModal title={"Add " + modalTitle} open={open} setOpen={setOpen}>
        <Form />
      </MainModal>
    </Toolbar>
  );
}

interface MainTableTypes {
  tableName: string;
  // @ts-ignore
  headCells: any[];
  // @ts-ignore
  rows: any[];
  modalTitle: string;
  form: () => JSX.Element;
  deleteFunc: (id: string) => any;
}

export default function MainTable({
  tableName,
  headCells,
  rows,
  modalTitle,
  form,
  deleteFunc,
}: MainTableTypes) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("calories");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = React.useState(rows);

  React.useEffect(() => {
    setSearch(rows);
  }, [rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = search?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search.length) : 0;

  // console.log("selected", selected);

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#08071b",
        borderRadius: "10px",
        padding: "5px",
        paddingBottom: "0px",
      }}
    >
      <Paper
        sx={{ width: "100%", mb: 2, borderRadius: "10px", border: "none" }}
      >
        <EnhancedTableToolbar
          data={rows}
          setSearch={setSearch}
          selectedIds={selected}
          numSelected={selected.length}
          setSelected={setSelected}
          selectedId={selected[0]}
          tableName={tableName}
          modalTitle={modalTitle}
          Form={form}
          deleteFunc={deleteFunc}
        />
        <TableContainer className="mainTable">
          <Table
            sx={{
              minWidth: 750,
              bgcolor: "#08071b",
              overflowX: "scroll",
            }}
            aria-labelledby="tableTitle"
            // size="small"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={search.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(search, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const isItemSelected = isSelected(String(row._id));
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // const { _id, ...rowsWithoutId } = row;
                  // console.log("rowsWithoutId", rowsWithoutId);

                  const rowKeys = headCells?.map((item) => item.name);

                  return (
                    <TableRow
                      sx={{ border: "none" }}
                      hover
                      onClick={(event) => handleClick(event, String(row._id))}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={"TableRow" + row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          sx={{ color: "#fff", opacity: "0.5" }}
                          // color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      {rowKeys?.map((key, i) => (
                        <TableCell
                          key={"TableCell" + key}
                          sx={{ color: "#fff", opacity: "0.5" }}
                          align="left"
                          component="th"
                          id={labelId}
                          scope="row"
                          // padding="none"
                        >
                          {/* {row[key]} */}
                          {String(row[key]).startsWith("https://") ||
                          String(row[key]).startsWith("http://") ? (
                            <a
                              href={String(row[key])}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: "inherit" }}
                            >
                              {/* link */}
                              {/* <LinkIcon /> */}
                              <OpenInNewIcon />
                            </a>
                          ) : (
                            row[key]
                          )}
                        </TableCell>
                      ))}
                      {/* <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.companyName}
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            bgcolor: "#08071b",
            color: "rgba(256, 255, 255, 0.7)",
            // borderRadius: "0 0 10px 10px",
            borderRadius: "0 0 3px 3px",
            svg: { color: "#fff", opacity: 0.7 },
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={search.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}
