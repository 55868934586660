import React from "react";
import Dashboard from "@/components/dashboard";
import MainTable from "@/components/common/MainTable";
import CompanyAddForm from "@/components/dashboard/menu/Company/CompanyAddForm";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  getCompanyData,
  getAllCompanyAsync,
  deleteCompanyAsync,
} from "@/redux/slices/companySlice";
import { formatDate } from "@/utils";

interface Data {
  _id: string;
  no: number;
  companyName: string;
  companyAddress: string;
  companyPhone: string;
  companyEmail: string;
  companyWebsite: string;
  companyLogo: string;
  companyDescription: string;
  createdAt: string;
  updatedAt: string;
}

// interface HeadCell {
//   id: string;
//   label: string;
//   name: string;
//   numeric: boolean;
//   disablePadding: boolean;
// }

const headCells = [
  {
    id: "0",
    label: "#",
    name: "no",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "2",
    label: "Company Name",
    name: "companyName",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "3",
    label: "Address",
    name: "companyAddress",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "4",
    label: "Phone",
    name: "companyPhone",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "5",
    label: "Email",
    name: "companyEmail",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "6",
    label: "Website",
    name: "companyWebsite",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "7",
    label: "Logo",
    name: "companyLogo",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "8",
    label: "Description",
    name: "companyDescription",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "9",
    label: "Create Date",
    name: "createdAt",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "10",
    label: "Update Date",
    name: "updatedAt",
    numeric: false,
    disablePadding: false,
  },
];

const Company = () => {
  const dispatch = useAppDispatch();
  const { data }: any = useAppSelector(getCompanyData);
  const [rows, setRows] = React.useState<Data[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllCompanyAsync());
    };
    fetchData();
  }, [dispatch]);

  React.useEffect(() => {
    if (data && data.length > 0) {
      const newData = data.map((item: any, i: number) => {
        const {
          _id,
          companyName,
          companyAddress,
          companyPhone,
          companyEmail,
          companyWebsite,
          companyLogo,
          companyDescription,
          createdAt,
          updatedAt,
        } = item;
        return {
          _id,
          no: i + 1,
          companyName,
          companyAddress,
          companyPhone,
          companyEmail,
          companyWebsite,
          companyLogo,
          companyDescription,
          createdAt: formatDate(createdAt),
          updatedAt: formatDate(updatedAt),
        };
      });
      setRows(newData);
    } else {
      setRows([]);
    }
  }, [data]);
  return (
    <Dashboard>
      <MainTable
        tableName="Company List"
        headCells={headCells}
        rows={rows}
        modalTitle="Company"
        form={CompanyAddForm}
        deleteFunc={deleteCompanyAsync}
      />
    </Dashboard>
  );
};

export default Company;
