import * as React from "react";
// import { Box, Paper, Grid } from "@mui/material";
// import { styled } from "@mui/material/styles";
import styles from "@/styles/dashboard-futures.module.css";
import Dashboard from "@/components/dashboard";
import Copyright from "@/components/dashboard/Copyright";
// import {
//   dashboardFuturesWalletItems,
//   dashboardFuturesProItems,
//   dashboardFuturesPlatformItems,
// } from "../../../data";
// import usdIcon from "@/assets/icons/usd.svg";
import {
  // PlatformItem, ProItem,
  WalletItem,
} from "./util-components";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  getAllAmountAsync,
  getDashboardData,
} from "@/redux/slices/dashboardSlice";
import walletIcon from "@/assets/icons/wallet.svg";
import { getProfileAsync, getProfileData } from "@/redux/slices/profileSlice";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

const DashboardMain = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(getDashboardData);
  const profileData = useAppSelector(getProfileData);

  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllAmountAsync());
      await dispatch(getProfileAsync());
    };
    fetchData();
  }, [dispatch]);

  // console.log("data", data);
  // console.log("profile: ", profileData);

  const amount = [
    {
      id: "1",
      amount: data?.totalBalance || 0,
      title: "Total Balance",
    },
    {
      id: "2",
      amount: data?.totalCost || 0,
      title: "Total Cost",
    },
    {
      id: "3",
      amount: data?.totalAdvance || 0,
      title: "Total Advance",
    },
  ];

  return (
    <Dashboard>
      {/* dashboard */}
      <div className={styles.dashboardSection} style={{ marginBottom: 40 }}>
        <aside>
          <h1
            className={styles.section__title}
            style={{ margin: 0, marginBottom: "5px" }}
          >
            Dashboard
          </h1>
          {/* <div style={{ display: "flex", flexDirection: "row" }}>
            <p>Account: py149273</p>
            <button>Add New Account</button>
          </div> */}
        </aside>
        <aside style={{ display: "flex", flexDirection: "row" }}>
          {/* <img src={usdIcon} alt="usdIcon" />
          <h2>Balance: 5,587 USD</h2> */}
          <div style={{}}>
            <p>Name: {profileData?.data?.name}</p>
            <p>Username: {profileData?.data?.username}</p>
            <p>Email: {profileData?.data?.email}</p>
          </div>
        </aside>
      </div>

      {/* wallet info */}
      <section
        className={`${styles.flex} ${styles.walletItemSection}`}
        style={{ marginBottom: 40 }}
      >
        {amount?.map((item) => (
          <WalletItem
            key={item.id}
            amount={item.amount}
            title={item.title}
            icon={walletIcon}
          />
        ))}
      </section>

      {/* pro items */}
      {/* <section className={styles.flex}>
        {dashboardFuturesProItems?.map((item) => (
          <ProItem
            key={item.id}
            title={item.title}
            desc={item.desc}
            btnText={item.btnText}
            option={item.option}
          />
        ))}
      </section> */}

      {/* platforms */}
      {/* <section>
        <h1 className={styles.section__title}>Platforms</h1>

        <div className={`${styles.flex} ${styles.platformItemSection}`}>
          {dashboardFuturesPlatformItems?.map((item) => (
            <PlatformItem key={item.id} title={item.title} icon={item.icon} />
          ))}
        </div>
      </section> */}

      {/* copyright */}
      <Copyright />
    </Dashboard>
  );
};

export default DashboardMain;
