import * as React from "react";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import styles from "@/styles/main-input.module.css";

interface MainDateProps {
  title?: string;
  name: string;
  date: Dayjs | null;
  setDate: (newDate: Dayjs | null) => void;
  required?: boolean;
}

export default function MaterialUIPickers({
  title,
  name,
  date,
  setDate,
  required,
}: MainDateProps) {
  // const [value, setValue] = React.useState<Dayjs | null>(dayjs(new Date()));

  // const handleChange = (newValue: Dayjs | null) => {
  //   setValue(newValue);
  // };

  return (
    <div className={styles.main_input_section}>
      {title && (
        <label htmlFor={name} className={styles.title}>
          <span className={styles.required}>{required && "* "}</span>
          {title}
        </label>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          // label="Date desktop"
          inputFormat="MM/DD/YYYY"
          value={date}
          onChange={setDate}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                width: "100%",
                marginTop: "0.5rem",
                marginBottom: "1rem",
                border: "1px solid #828282",
                borderRadius: "6px",
                input: { color: "#fff" },
                svg: { color: "#fff" },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
