import React from "react";
import Dashboard from "@/components/dashboard";
import MainTable from "@/components/common/MainTable";
import OthersAddForm from "./OthersAddForm";
import GoogleDriveFileUploader from "@/components/Upload";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  addOthersAsync,
  deleteOthersAsync,
  getAllOthersAsync,
  getOthersData,
  updateOthersAsync,
} from "@/redux/slices/othersSlice";
import { formatDate } from "@/utils";

interface Data {
  _id: string;
  no: number;
  note: string;
  photo: string;
  pdf: string;
  createdAt: string;
  updatedAt: string;
}

const headCells = [
  {
    id: "0",
    label: "#",
    name: "no",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "1",
    label: "Note",
    name: "note",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "2",
    label: "Create Date",
    name: "createdAt",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "3",
    label: "Update Date",
    name: "updatedAt",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "4",
    label: "Photo",
    name: "photo",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "5",
    label: "PDF Link",
    name: "pdf",
    numeric: false,
    disablePadding: false,
  },
];

const Others = () => {
  const dispatch = useAppDispatch();
  const { data }: any = useAppSelector(getOthersData);
  const [rows, setRows] = React.useState<Data[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllOthersAsync());
    };
    fetchData();
  }, [dispatch]);

  React.useEffect(() => {
    if (data && data.length > 0) {
      const newData = data.map((item: any, i: number) => {
        const { _id, note, photo, pdf, createdAt, updatedAt } = item;
        return {
          _id,
          no: i + 1,
          note,
          photo,
          pdf,
          createdAt: formatDate(createdAt),
          updatedAt: formatDate(updatedAt),
        };
      });
      setRows(newData);
    } else {
      setRows([]);
    }
  }, [data]);

  return (
    <Dashboard>
      <MainTable
        tableName="Others List"
        headCells={headCells}
        rows={rows}
        modalTitle="Add Other"
        form={OthersAddForm}
        // addFunc={addOthersAsync}
        deleteFunc={deleteOthersAsync}
        // updateFunc={updateOthersAsync}
      />

      <GoogleDriveFileUploader />
    </Dashboard>
  );
};

export default Others;
