import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import dashboardReducer from "./slices/dashboardSlice";
import advanceReducer from "./slices/advanceSlice";
import costReducer from "./slices/costSlice";
import companyReducer from "./slices/companySlice";
import purposeReducer from "./slices/purposeSlice";
import otherseReducer from "./slices/othersSlice";
import bankReducer from "./slices/bankSlice";
import profileReducer from "./slices/profileSlice";

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    advance: advanceReducer,
    cost: costReducer,
    company: companyReducer,
    purpose: purposeReducer,
    others: otherseReducer,
    bank: bankReducer,
    profile: profileReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
