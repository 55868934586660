import styles from "@/styles/main-input.module.css";

interface MainInputPops {
  type?: string;
  title?: string;
  name: string;
  placeholder: string;
  value: string;
  setValue: (e: any) => void;
  required?: boolean;
  textarea?: boolean;
  onWheel?: (e: any) => void;
}

const MainInput = ({
  type = "text",
  title,
  name,
  placeholder,
  value,
  setValue,
  required,
  textarea,
  onWheel,
}: MainInputPops) => {
  return (
    <div className={styles.main_input_section}>
      {title && (
        <label htmlFor={name} className={styles.title}>
          <span className={styles.required}>{required && "* "}</span>
          {title}
        </label>
      )}

      {textarea ? (
        <textarea
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={setValue}
          className={styles.main_input}
          style={{ height: "100px", fontFamily: "inherit" }}
        />
      ) : (
        <input
          type={type}
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={setValue}
          className={styles.main_input}
          onWheel={onWheel}
        />
      )}
    </div>
  );
};

export default MainInput;
