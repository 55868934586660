import { Link, useLocation } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
// import logo from "@/assets/icons/logo.svg";
import futuresIcon from "@/assets/icons/futures.svg";
// import forexIcon from "@/assets/icons/forex.svg";
// import platformsIcon from "@/assets/icons/platforms.svg";
import advance from "@/assets/icons/advance.svg";
import cost from "@/assets/icons/cost.svg";
import company from "@/assets/icons/company.svg";
import purpose from "@/assets/icons/purpose.svg";
import others from "@/assets/icons/others.svg";
import bank from "@/assets/icons/bank.svg";

interface SidebarProps {
  drawerWidth: number;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const Sidebar = ({
  drawerWidth,
  mobileOpen,
  handleDrawerToggle,
}: SidebarProps) => {
  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            background: "#08071B",
          },
        }}
      >
        <SidebarContent />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            background: "#08071B",
          },
        }}
        open
      >
        <SidebarContent />
      </Drawer>
    </Box>
  );
};

const SidebarContent = () => {
  const { pathname } = useLocation();

  const sidebarItems = [
    {
      id: "1",
      text: "Dashboard",
      icon: futuresIcon,
      // link: "/dashboard/futures",
      link: "/dashboard",
      isActive:
        // pathname === "/dashboard/futures" ||
        // pathname === "/dashboard/futures/" ||
        pathname === "/dashboard" || pathname === "/dashboard/",
    },
    // {
    //   id: "2",
    //   text: "Forex",
    //   icon: forexIcon,
    //   link: "/dashboard/forex",
    //   isActive:
    //     pathname === "/dashboard/forex" || pathname === "/dashboard/forex/",
    // },
    // {
    //   id: "3",
    //   text: "Platforms",
    //   icon: platformsIcon,
    //   link: "/dashboard/platforms",
    //   isActive:
    //     pathname === "/dashboard/platforms" ||
    //     pathname === "/dashboard/platforms/",
    // },
    {
      id: "2",
      text: "Advance",
      icon: advance,
      link: "/dashboard/advance",
      isActive:
        pathname === "/dashboard/advance" || pathname === "/dashboard/advance/",
    },
    {
      id: "3",
      text: "Cost",
      icon: cost,
      link: "/dashboard/cost",
      isActive:
        pathname === "/dashboard/cost" || pathname === "/dashboard/cost/",
    },
    {
      id: "4",
      text: "Company",
      icon: company,
      link: "/dashboard/company",
      isActive:
        pathname === "/dashboard/company" || pathname === "/dashboard/company/",
    },
    {
      id: "5",
      text: "Bank",
      icon: bank,
      link: "/dashboard/bank",
      isActive:
        pathname === "/dashboard/bank" || pathname === "/dashboard/bank/",
    },
    {
      id: "6",
      text: "Purpose",
      icon: purpose,
      link: "/dashboard/purpose",
      isActive:
        pathname === "/dashboard/purpose" || pathname === "/dashboard/purpose/",
    },
    {
      id: "7",
      text: "Others",
      icon: others,
      link: "/dashboard/others",
      isActive:
        pathname === "/dashboard/others" || pathname === "/dashboard/others/",
    },
  ];

  return (
    // @ts-ignore
    <div style={styles.sidebarContent}>
      {/* <img src={logo} alt="logo" style={styles.logo} /> */}
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <img
          src="/ahsan.jpeg"
          alt="Ahsan"
          style={{ height: "40px", width: "40px", borderRadius: "6px" }}
        />
        <h1 style={{ color: "white", fontSize: "25px" }}>Ahsan</h1>
      </div>

      {/* @ts-ignore */}
      <section style={styles.mainSection}>
        <aside>
          {sidebarItems.map((item) => (
            <Link
              to={item.link}
              key={item.id}
              style={{ textDecoration: "none" }}
            >
              <div
                style={{
                  ...styles.item,
                  background: item.isActive ? "#3F94F7" : "transparent",
                }}
              >
                <img
                  src={item.icon}
                  alt={item.text}
                  style={{
                    ...styles.itemIcon,

                    opacity: item.isActive ? "1" : "0.5",
                  }}
                />
                <p
                  style={{
                    ...styles.itemText,
                    opacity: item.isActive ? "1" : "0.5",
                  }}
                >
                  {item.text}
                </p>
              </div>
            </Link>
          ))}
        </aside>

        {/* <aside style={styles.upgradeSection}>
          <h1 style={styles.upgradeSection__text}>
            Upgrade your Account to Pro
          </h1>
          <button style={styles.upgradeSection__btn}>Upgrade Now</button>

          <div style={styles.upgradeSection__circle}></div>
        </aside> */}
      </section>
    </div>
  );
};

const styles = {
  sidebarContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    zIndex: "100",
  },
  logo: {
    width: 188,
    height: "auto",
    marginTop: 20,
  },
  mainSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    marginTop: "30px",
    marginBottom: "50px",
  },
  item: {
    display: "flex",
    alignItems: "center",
    width: "228px",
    // height: "68px",
    borderRadius: "10px",
  },
  itemIcon: {
    width: "24px",
    height: "24px",
    margin: "0 20px",
  },
  itemText: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    color: "#FFFFFF",
  },
  upgradeSection: {
    width: "224px",
    height: "176px",
    background: "#1890FF",
    borderRadius: "12px",
    padding: "20px",
    position: "relative",
    overflow: "hidden",
  },
  upgradeSection__text: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#FFFFFF",
    marginTop: "10px",
  },
  upgradeSection__btn: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    padding: "8px 16px",
    background: "#FFFFFF",
    color: "#1890FF",
    borderRadius: "6px",
    border: "none",
  },
  upgradeSection__circle: {
    position: "absolute",
    width: "165px",
    height: "165px",
    borderRadius: "50%",
    right: -40,
    bottom: -45,
    background: "#FFFFFF",
    opacity: "0.15",
  },
};

export default Sidebar;
