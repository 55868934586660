import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { callApi } from "../apiHelper";
import { RootState, AppThunk } from "../store";

export interface ProfileData {
  id: string;
  name: string;
  username: string;
  email: string;
}

interface ProfileState {
  status: "success" | "error" | "";
  statusCode: number;
  message: string;
  data: ProfileData;
}

const initialState: ProfileState = {
  status: "",
  statusCode: 0,
  message: "",
  data: {
    id: "",
    name: "",
    username: "",
    email: "",
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<ProfileState>) => {
      state.status = action.payload.status;
      state.statusCode = action.payload.statusCode;
      state.message = action.payload.message;
      state.data = action.payload.data;
    },
  },
});

const { setProfile } = profileSlice.actions;

export const getProfileAsync = (): AppThunk => async (dispatch) => {
  const response = await callApi("GET", "/auth/profile");
  await dispatch(setProfile(response));
  // return response;
};

type UpdateProfileDataType = {
  name: string;
  username: string;
  email: string;
};

export const updateProfileAsync =
  ({ name, username, email }: UpdateProfileDataType): AppThunk =>
  async (dispatch) => {
    const reqData = {
      name,
      username,
      email,
    };

    // console.log("update: ", reqData);
    const response = await callApi("PUT", `/auth/profile`, reqData);
    await dispatch(getProfileAsync());
    // console.log("update response: ", response);
    return response;
  };

// export const deleteProfileAsync =
//   (): AppThunk =>
//   async (dispatch) => {
//     const response = await callApi("DELETE", `/profile`);
//     await dispatch(getAllProfileAsync());
//     return response;
//   };
type ChangePasswordDataType = {
  oldPassword: string;
  password: string;
};

export const changePasswordAsync =
  ({ oldPassword, password }: ChangePasswordDataType): AppThunk =>
  async () => {
    const reqData = {
      oldPassword,
      password,
    };
    // console.log("update: ", reqData);
    const response = await callApi("PUT", `/auth/change-password`, reqData);
    // console.log("respnse data: ", response);
    return response;
  };

  

export const getProfileData = (state: RootState) => state.profile;
export default profileSlice.reducer;
