import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { callApi } from "../apiHelper";
import { RootState, AppThunk } from "../store";

export interface PurposeData {
  _id: string;
  purposeTitle: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface PurposeState {
  status: "success" | "error" | "";
  statusCode: number;
  message: string;
  data: PurposeData[];
}

const initialState: PurposeState = {
  status: "",
  statusCode: 0,
  message: "",
  data: [],
};

export const PurposeSlice = createSlice({
  name: "purpose",
  initialState,
  reducers: {
    setPurpose: (state, action: PayloadAction<PurposeState>) => {
      state.status = action.payload.status;
      state.statusCode = action.payload.statusCode;
      state.message = action.payload.message;
      state.data = action.payload.data;
    },
  },
});

const { setPurpose } = PurposeSlice.actions;

export const getAllPurposeAsync = (): AppThunk => async (dispatch) => {
  const response = await callApi("GET", "/purpose");
  await dispatch(setPurpose(response));
};

export const getPurposeByIdAsync =
  (id: string): AppThunk =>
  async () => {
    const response = await callApi("GET", `/purpose/${id}`);
    return response;
  };

type AddPurposeDataType = {
  purposeTitle: string;
};

export const addPurposeAsync =
  ({ purposeTitle }: AddPurposeDataType): AppThunk =>
  async (dispatch) => {
    const reqData = {
      purposeTitle,
    };

    const response = await callApi("POST", "/purpose", reqData);
    await dispatch(getAllPurposeAsync());
    return response;
  };

type UpdatePurposeDataType = {
  id: string;
  purposeTitle: string;
};

export const updatePurposeAsync =
  ({ id, purposeTitle }: UpdatePurposeDataType): AppThunk =>
  async (dispatch) => {
    const reqData = {
      purposeTitle,
    };
    const response = await callApi("PUT", `/purpose/${id}`, reqData);
    await dispatch(getAllPurposeAsync());
    return response;
  };

export const deletePurposeAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    const response = await callApi("DELETE", `/purpose/${id}`);
    await dispatch(getAllPurposeAsync());
    return response;
  };

export const getPurposeData = (state: RootState) => state.purpose;
export default PurposeSlice.reducer;
