import React from "react";
import Dashboard from "@/components/dashboard";
import MainTable from "@/components/common/MainTable";
import BankAddForm from "@/components/dashboard/menu/Bank/BankAddForm";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  getBankData,
  getAllBankAsync,
  deleteBankAsync,
} from "@/redux/slices/bankSlice";
import { formatDate } from "@/utils";

interface Data {
  _id: string;
  no: number;
  bankName: string;
  bankLogo: string;
  bankDescription: string;
  createdAt: string;
  updatedAt: string;
}
const headCells = [
  {
    id: "0",
    label: "#",
    name: "no",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "1",
    label: "Bank Name",
    name: "bankName",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "2",
    label: "Bank Logo",
    name: "bankLogo",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "3",
    label: "Bank Description",
    name: "bankDescription",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "4",
    label: "Create Date",
    name: "createdAt",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "5",
    label: "Update Date",
    name: "updatedAt",
    numeric: false,
    disablePadding: false,
  },
];

const Bank = () => {
  const dispatch = useAppDispatch();
  const { data }: any = useAppSelector(getBankData);
  const [rows, setRows] = React.useState<Data[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllBankAsync());
    };
    fetchData();
  }, [dispatch]);

  React.useEffect(() => {
    if (data && data.length > 0) {
      const newData = data.map((item: any, i: number) => {
        const {
          _id,
          bankName,
          bankLogo,
          bankDescription,
          createdAt,
          updatedAt,
        } = item;
        return {
          _id,
          no: i + 1,
          bankName,
          bankLogo,
          bankDescription,
          createdAt: formatDate(createdAt),
          updatedAt: formatDate(updatedAt),
        };
      });
      setRows(newData);
    } else {
      setRows([]);
    }
  }, [data]);
  return (
    <Dashboard>
      <MainTable
        tableName="Bank List"
        headCells={headCells}
        rows={rows}
        modalTitle="Bank"
        form={BankAddForm}
        deleteFunc={deleteBankAsync}
      />
    </Dashboard>
  );
};

export default Bank;
