import styles from "@/styles/dashboard-futures.module.css";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";

interface WalletItemProps {
  amount: number;
  title: string;
  icon: string;
}

interface ProItemProps {
  title: string;
  desc: string;
  btnText: string;
  option?: string;
}

interface PlatformItemProps {
  title: string;
  icon: string;
}

export const WalletItem = ({ amount, title, icon }: WalletItemProps) => {
  return (
    <div className={styles.wallet__item}>
      <div className={styles.wallet__item__imgSection}>
        <img src={icon} alt={title} />
      </div>
      <div style={{ paddingLeft: "20px" }}>
        <p>{title}</p>
        <h1>
          {amount} <span style={{ fontSize: "12px" }}>BDT</span>
        </h1>
      </div>
    </div>
  );
};

export const ProItem = ({ title, desc, btnText, option }: ProItemProps) => {
  const [checked, setChecked] = useState(true);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  // console.log("checked", checked);

  return (
    <div className={styles.pro__item}>
      <h1>{title}</h1>
      <p>{desc}</p>
      <div>
        <button>{btnText}</button>
        {option && (
          <FormControlLabel
            label={option}
            control={
              <Checkbox
                checked={checked}
                onChange={handleCheck}
                sx={{ color: "#fff" }}
              />
            }
            sx={{
              color: "#fff",
              marginLeft: "20px",
              "& .MuiTypography-root": {
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export const PlatformItem = ({ title, icon }: PlatformItemProps) => {
  return (
    <div className={styles.platform__item}>
      <img src={icon} alt={title} />
      <h1>{title}</h1>
    </div>
  );
};
