import { useEffect, useState } from "react";
import styles from "@/styles/auth.module.css";
import authBg from "@/assets/images/auth-BG.png";
import MainInput from "@/components/common/MainInput";
import MainButton from "@/components/common/MainButton";
import { validateEmail } from "@/utils";
import { useAppDispatch } from "@/redux/hooks";

import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { resetPasswordAsync } from "@/redux/slices/authSlice";
import { notifyError, notifySuccess } from "../common/MainNotify";

const ResetPassword = () => {
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleOnChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const reqData = {
      ...formData,
      token: token || "",
    };

    // ready for api call
    // console.log("reqData: ", reqData);
    const res: any = await dispatch(resetPasswordAsync(reqData));

    if (res.status === "success") {
      setFormData({
        password: "",
        confirmPassword: "",
      });

      notifySuccess(res.message);
      window.location.href = "/login";
      setLoading(false);
    } else {
      notifyError(res.message);
      setLoading(false);
    }
  };

  // const handleLogin = async (e: any) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   setError("");

  //   const isEmail = validateEmail(formData.emailOrUsername);
  //   const _emailOrUsername = formData.emailOrUsername.toLowerCase();

  //   const _formData = {
  //     username: isEmail ? "" : _emailOrUsername,
  //     email: isEmail ? _emailOrUsername : "",
  //     password: formData.password,
  //   };

  //   console.log("Login formData", _formData);

  //   let res: any;

  //   if (isEmail) {
  //     res = await dispatch(loginByEmailAsync(_formData));
  //   } else {
  //     res = await dispatch(loginByUsernameAsync(_formData));
  //   }

  //   // console.log("Login res", res)

  //   if (res.status === "success") {
  //     console.log("Login success", res);
  //     setError("");
  //     if (res.data.accessToken) {
  //       console.log("res.data.accessToken", res.data.accessToken);
  //       await Cookies.set("accessToken", res.data.accessToken, { expires: 1 });
  //       window.location.href = "/dashboard";
  //       setLoading(false);
  //     } else {
  //       console.log("No accessToken");
  //       setLoading(false);
  //     }
  //     // navigate("/dashboard");
  //     // redirect("/dashboard");
  //   } else {
  //     console.log("Login error", res);
  //     setError(res.message);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   console.log("accessToken", Cookies.get("accessToken"));
  // }, [loading]);

  return (
    <section className={`${styles.authSection} ${styles.loginSection}`}>
      <aside
        className={`${styles.w_50} ${styles.auth_leftSection} ${styles.login_leftSection}`}
        style={{
          backgroundImage: `url(${authBg})`,
        }}
      ></aside>
      <aside
        className={`${styles.w_50} ${styles.auth_rightSection} ${styles.login_rightSection}`}
      >
        {/* <img src={logo} alt="logo" className={styles.logo} /> */}
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <img
            src="/ahsan.jpeg"
            alt="Ahsan"
            style={{ height: "50px", width: "50px", borderRadius: "50%" }}
          />
          <h1 style={{ color: "white", fontSize: "25px" }}>Ahsan</h1>
        </div>
        <h1 className={styles.title}>Welcome Back</h1>
        <p className={styles.desc}>Welcome back! Please enter your details.</p>

        <form onSubmit={handleSubmit} className={styles.form}>
          <MainInput
            type="password"
            title="Password"
            name="password"
            placeholder="Enter Current Password"
            value={formData.password}
            setValue={handleOnChange}
            required
          />

          <MainInput
            type="password"
            title="Confirm Password"
            name="confirmPassword"
            placeholder="Enter Confirm Password"
            value={formData.confirmPassword}
            setValue={handleOnChange}
            required
          />

          <p className={styles.errorText}>{error}</p>

          {loading ? (
            <CircularProgress />
          ) : (
            <MainButton type="button" text="Submit" onClick={handleSubmit} />
          )}
        </form>
      </aside>
    </section>
  );
  // }
};

export default ResetPassword;
