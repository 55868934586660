import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { callApi } from "../apiHelper";
import { RootState, AppThunk } from "../store";


export interface BankData {
  _id: string;
  bankName: string;
  bankLogo: string;
  bankDescription: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface BankState {
  status: "success" | "error" | "";
  statusCode: number;
  message: string;
  data: BankData[];
}

const initialState: BankState = {
  status: "",
  statusCode: 0,
  message: "",
  data: [],
};

export const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    setBank: (state, action: PayloadAction<BankState>) => {
      state.status = action.payload.status;
      state.statusCode = action.payload.statusCode;
      state.message = action.payload.message;
      state.data = action.payload.data;
    },
  },
});

const { setBank } = bankSlice.actions;

export const getAllBankAsync = (): AppThunk => async (dispatch) => {
  const response = await callApi("GET", "/bank");
  await dispatch(setBank(response));
  // return response;
};

export const getBankByIdAsync =
  (id: string): AppThunk =>
  async () => {
    const response = await callApi("GET", `/bank/${id}`);
    return response;
  };

type AddBankDataType = {
  bankName: string;
  bankLogo: string;
  bankDescription: string;
};

export const addBankAsync =
  ({ bankName, bankLogo, bankDescription }: AddBankDataType): AppThunk =>
  async (dispatch) => {
    const reqData = {
      bankName,
      bankLogo,
      bankDescription,
    };
    const response = await callApi("POST", "/bank", reqData);
    await dispatch(getAllBankAsync());
    return response;
  };

type UpdateBankDataType = {
  id: string;
  bankName: string;
  bankLogo: string;
  bankDescription: string;
};

export const updateBankAsync =
  ({ id, bankName, bankLogo, bankDescription }: UpdateBankDataType): AppThunk =>
  async (dispatch) => {
    const reqData = {
      bankName,
      bankLogo,
      bankDescription,
    };
    const response = await callApi("PUT", `/bank/${id}`, reqData);
    await dispatch(getAllBankAsync());
    return response;
  };

export const deleteBankAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    const response = await callApi("DELETE", `/bank/${id}`);
    await dispatch(getAllBankAsync());
    return response;
  };

export const getBankData = (state: RootState) => state.bank;
export default bankSlice.reducer;
