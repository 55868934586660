import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import close from "@/assets/icons/close.svg";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: 500,
  // overflowY: "scroll",
  overflowY: "auto",
  // bgcolor: "background.paper",
  bgcolor: "#08071b",
  // border: "2px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

interface MainModalProps {
  title?: string;
  open: boolean;
  setOpen: (e: boolean) => void;
  children: React.ReactNode;
}

export default function MainModal({
  title,
  open,
  setOpen,
  children,
}: MainModalProps) {
  return (
    <div>
      <Modal
        open={open}
        // onClose={() => setOpen(false)}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="mainModal">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <h3 style={{ color: "#fff", textAlign: "center" }}>{title}</h3>
            <Button onClick={() => setOpen(false)}>
              <img
                src={close}
                alt="ahsan"
                style={{ height: "22px", width: "22px" }}
              />
            </Button>
          </Box>

          {children}
        </Box>
      </Modal>
    </div>
  );
}
