import React, { useState } from "react";
import Cookies from "js-cookie";
import { notifyError } from "./common/MainNotify";
import { CircularProgress } from "@mui/material";

function GoogleDriveFileUploader() {
  const [url, setUrl] = useState("");
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file.data);

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + Cookies.get("accessToken"),
      },
    });

    const responseWithBody = await response.json();

    if (response) {
      // console.log("response", responseWithBody);
      setUrl(responseWithBody.data.file);
      setLoading(false);
    } else {
      // console.log("Error");
      notifyError(responseWithBody.message);
      setLoading(false);
    }
  };

  const handleFileChange = (e: any) => {
    setUrl("");

    const file = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setFile(file);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{ display: "grid", gridRowGap: "20px" }}
      >
        <input
          type="file"
          name="file"
          onChange={handleFileChange}
          style={{ color: "#fff" }}
        />
        {loading ? <CircularProgress /> : <button type="submit">Submit</button>}
      </form>
      {url && <p style={{ color: "#fff" }}>{url}</p>}
    </div>
  );
}

export default GoogleDriveFileUploader;
