import React from "react";
import Dashboard from "@/components/dashboard";
import MainTable from "@/components/common/MainTable";
import AdvanceAddForm from "@/components/dashboard/menu/Advance/AdvanceAddForm";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  deleteAdvanceAsync,
  // AdvanceState,
  getAdvanceData,
  getAllAdvanceAsync,
} from "@/redux/slices/advanceSlice";
import { formatDate } from "@/utils";

interface Data {
  _id: string;
  no: number;
  companyName: string;
  bankName: string;
  amount: number;
  chequeNo: string;
  purpose: string;
  date: number;
  photo: string;
  pdf: string;
  note: string;
}

// interface HeadCell {
//   id: string;
//   label: string;
//   name: string;
//   numeric: boolean;
//   disablePadding: boolean;
// }

const headCells = [
  {
    id: "0",
    label: "#",
    name: "no",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "1",
    label: "Company",
    name: "companyName",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "2",
    label: "Bank",
    name: "bankName",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "3",
    label: "Amount",
    name: "amount",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "4",
    label: "Check No",
    name: "chequeNo",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "5",
    label: "Purpose",
    name: "purpose",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "6",
    label: "Date",
    name: "date",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "7",
    label: "Photo",
    name: "photo",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "8",
    label: "PDF Link",
    name: "pdf",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "9",
    label: "Note",
    name: "note",
    numeric: false,
    disablePadding: false,
  },
];

const Advance = () => {
  const dispatch = useAppDispatch();
  const { data }: any = useAppSelector(getAdvanceData);
  const [rows, setRows] = React.useState<Data[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllAdvanceAsync());
    };
    fetchData();
  }, [dispatch]);

  React.useEffect(() => {
    if (data && data.length > 0) {
      const newData = data.map((item: any, i: number) => {
        const {
          _id,
          company,
          bank,
          chequeNo,
          amount,
          date,
          purpose,
          photo,
          pdf,
          note,
        } = item;
        return {
          _id,
          no: i + 1,
          companyName: company?.companyName || "",
          bankName: bank?.bankName || "",
          chequeNo,
          amount,
          // date: timestampToDate(date),
          date: formatDate(date),
          purpose: purpose?.purposeTitle || "",
          photo,
          note,
          pdf,
        };
      });
      setRows(newData);
    } else {
      setRows([]);
    }
  }, [data]);

  return (
    <Dashboard>
      <MainTable
        tableName="Advance Amount List"
        headCells={headCells}
        rows={rows}
        modalTitle="Advance"
        form={AdvanceAddForm}
        deleteFunc={deleteAdvanceAsync}
      />
    </Dashboard>
  );
};

export default Advance;
