import React from "react";
import MainDate from "@/components/common/MainDate";
import MainSelect from "@/components/common/MainSelect";
import MainInput from "@/components/common/MainInput";
import MainButton from "@/components/common/MainButton";
import dayjs, { Dayjs } from "dayjs";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { addAdvanceAsync } from "@/redux/slices/advanceSlice";
import { notifyError, notifySuccess } from "@/components/common/MainNotify";
import {
  getAllCompanyAsync,
  getCompanyData,
} from "@/redux/slices/companySlice";
import {
  getAllPurposeAsync,
  getPurposeData,
} from "@/redux/slices/purposeSlice";
import { getAllBankAsync, getBankData } from "@/redux/slices/bankSlice";
import { CircularProgress } from "@mui/material";

interface SelectDataType {
  label: string;
  id: string;
}

const AdvanceAddForm = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const companyData = useAppSelector(getCompanyData);
  const bankData = useAppSelector(getBankData);
  const purposeData = useAppSelector(getPurposeData);
  const [formData, setFormData] = React.useState({
    amount: 0,
    chequeNo: "",
    photo: "",
    pdf: "",
    note: "",
  });
  const [companyName, setCompanyName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [purpose, setPurpose] = React.useState("");
  const [date, setDate] = React.useState<Dayjs | null>(dayjs(new Date()));
  const [allCompaniesData, setAllCompaniesData] = React.useState<
    SelectDataType[]
  >([]);
  const [allBanksData, setAllBanksData] = React.useState<SelectDataType[]>([]);
  const [allPurposesData, setAllPurposesData] = React.useState<
    SelectDataType[]
  >([]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDate = (newDate: Dayjs | null) => {
    setDate(newDate);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const reqData = {
      ...formData,
      amount: Number(formData.amount),
      company: companyName,
      bank: bankName,
      purpose,
      date: date?.unix() || 0,
    };

    // console.log(reqData);
    const res: any = await dispatch(addAdvanceAsync(reqData));

    if (res.status === "success") {
      setFormData({
        amount: 0,
        chequeNo: "",
        photo: "",
        pdf: "",
        note: "",
      });
      setCompanyName("");
      setBankName("");
      setPurpose("");
      setDate(dayjs(new Date()));

      notifySuccess(res.message);
      setLoading(false);
    } else {
      notifyError(res.message);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllCompanyAsync());
      await dispatch(getAllBankAsync());
      await dispatch(getAllPurposeAsync());
    };
    fetchData();
  }, [dispatch]);

  React.useEffect(() => {
    if (companyData.data.length > 0) {
      const data = companyData.data.map((item) => ({
        label: item.companyName,
        id: item._id,
      }));
      setAllCompaniesData(data);
    }

    if (bankData.data.length > 0) {
      const data = bankData.data.map((item) => ({
        label: item.bankName,
        id: item._id,
      }));
      setAllBanksData(data);
    }

    if (purposeData.data.length > 0) {
      const data = purposeData.data.map((item) => ({
        label: item.purposeTitle,
        id: item._id,
      }));
      setAllPurposesData(data);
    }
  }, [bankData.data, companyData, purposeData]);

  return (
    <div className="AddForm" style={{ backgroundColor: "transparent" }}>
      <MainSelect
        title="Company Name"
        name="companyName"
        data={allCompaniesData}
        setSelect={setCompanyName}
        required
      />
      {/* <MainInput
        title="Bank Name"
        name="bankName"
        placeholder="Enter Bank Name"
        value={formData.bankName}
        setValue={handleOnChange}
        required
      /> */}
      <MainSelect
        title="Bank Name"
        name="bankName"
        data={allBanksData}
        setSelect={setBankName}
        required
      />
      <MainInput
        type="number"
        title="Amount"
        name="amount"
        placeholder="0.00"
        value={String(formData.amount)}
        // setValue={handleOnChange}
        setValue={(e) =>
          setFormData({
            ...formData,
            [e.target.name]: Math.abs(Number(e.target.value)),
          })
        }
        onWheel={(e) => e.currentTarget.blur()}
        required
      />
      <MainInput
        title="Check No"
        name="chequeNo"
        placeholder="Enter your check no"
        value={formData.chequeNo}
        setValue={handleOnChange}
        required
      />
      <MainSelect
        title="Purpose"
        name="purpose"
        data={allPurposesData}
        setSelect={setPurpose}
        required
      />
      <MainDate
        title="Date"
        name="date"
        date={date}
        setDate={handleDate}
        required
      />
      <MainInput
        title="Image Link"
        name="photo"
        placeholder="Image Link"
        value={formData.photo}
        setValue={handleOnChange}
      />
      <MainInput
        title="PDF Link"
        name="pdf"
        placeholder="PDF Link"
        value={formData.pdf}
        setValue={handleOnChange}
      />
      <MainInput
        title="Note"
        name="note"
        placeholder="Type any note here"
        value={formData.note}
        setValue={handleOnChange}
        textarea
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <MainButton type="button" text="Submit" onClick={handleSubmit} />
      )}
    </div>
  );
};

export default AdvanceAddForm;
