import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { callApi } from "../apiHelper";
import { RootState, AppThunk } from "../store";

export interface AuthState {
  status: "success" | "error" | "";
  message: string;
  data: any;
}

const initialState: AuthState = {
  status: "",
  message: "",
  data: {
    id: "",
    name: "",
    username: "",
    email: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.data = action.payload.data;
    },
  },
});

const { setAuth } = authSlice.actions;

export const signupAsync =
  (data: any): AppThunk =>
  async (dispatch) => {
    const reqData = {
      name: data?.name || "",
      username: data.username,
      email: data.email,
      password: data.password,
    };
    const response = await callApi("POST", "/auth/signup", reqData);
    await dispatch(setAuth(response));
    return response;
  };

export const loginByUsernameAsync =
  (data: any): AppThunk =>
  async (dispatch) => {
    const reqData = {
      username: data.username,
      password: data.password,
    };
    const response = await callApi("POST", "/auth/signin-by-username", reqData);
    await dispatch(setAuth(response));
    return response;
  };

export const loginByEmailAsync =
  (data: any): AppThunk =>
  async (dispatch) => {
    const reqData = {
      email: data.email,
      password: data.password,
    };
    const response = await callApi("POST", "/auth/signin-by-email", reqData);
    await dispatch(setAuth(response));
    return response;
  };

type ResetPasswordDataType = {
  token: string;
  password: string;
  confirmPassword: string;
};

export const resetPasswordAsync =
  ({ token, password, confirmPassword }: ResetPasswordDataType): AppThunk =>
  async () => {
    const reqData = {
      password,
      confirmPassword,
    };
    // console.log("update: ", reqData);
    const response = await callApi(
      "POST",
      `/auth/reset-password/${token}`,
      reqData
    );
    // console.log("respnse data: ", response);
    return response;
  };

export const forgotPasswordRequestAsync =
  (email: string): AppThunk =>
  async () => {
    const reqData = { email };
    const response = await callApi(
      "POST",
      "/auth/forgot-password-request",
      reqData
    );
    return response;
  };

export const getAuthData = (state: RootState) => state.auth;
export default authSlice.reducer;
