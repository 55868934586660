import axios from "axios";
import cookie from "js-cookie";

let API_URL =
  process.env.REACT_APP_SERVER_URL || "http://localhost:3050/api/v1";

if (process.env.NODE_ENV === "production") {
  API_URL = "https://ahsanmia-server.vercel.app/api/v1";
}

export const Axios = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${accessToken}`,
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFormatedResponse = (res: any) => {
  // let status = "";
  let data = null;

  if (res?.status === 200) {
    // status = "success";
    data = res?.data?.data;
  } else {
    // status = "error";
  }

  const response = {
    ...res?.data,
    status: res?.data?.status,
    statusCode: res?.status,
    message: res?.data?.message,
    data,
  };

  // console.log('getFormatedResponse | response', response);

  if (response?.statusCode === 401) {
    cookie.remove("accessToken");
  }

  return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const callApi = async (type: string, url: string, reqBody?: any) => {
  try {
    let res;
    // const formData = new FormData();
    const formData = reqBody;

    // if (reqBody) {
    //   // camelCase to snake_case conversion and then append to formData
    //   Object.keys(reqBody).forEach((key) => {
    //     const newKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
    //     formData.append(newKey, reqBody[key]);
    //   });
    // }

    const headersOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("accessToken")}`,
      },
    };

    if (type === "GET") {
      res = await Axios.get(url, headersOptions);
    } else if (type === "POST") {
      res = await Axios.post(url, formData, headersOptions);
    } else if (type === "PUT") {
      res = await Axios.put(url, formData, headersOptions);
    } else if (type === "PATCH") {
      res = await Axios.patch(url, formData, headersOptions);
    } else if (type === "DELETE") {
      res = await Axios.delete(url, headersOptions);
    }

    // console.log('callApi | res', res);

    const resObj = await getFormatedResponse(res);
    return resObj;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // return getFormatedResponse(error.response);
    const resObj = await getFormatedResponse(error.response);
    return resObj;
  }
};
