import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { callApi } from "../apiHelper";
import { RootState, AppThunk } from "../store";
import { BankData } from "./bankSlice";
import { CompanyData } from "./companySlice";
import { PurposeData } from "./purposeSlice";

interface AdvanceData {
  _id: string;
  company: CompanyData[];
  amount: number;
  chequeNo: string;
  bank: BankData[];
  purpose: PurposeData[];
  date: number;
  photo: string;
  pdf: string;
  note: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface AdvanceState {
  status: "success" | "error" | "";
  statusCode: number;
  message: string;
  data: AdvanceData[];
}

const initialState: AdvanceState = {
  status: "",
  statusCode: 0,
  message: "",
  data: [],
};

export const advanceSlice = createSlice({
  name: "advance",
  initialState,
  reducers: {
    setAdvance: (state, action: PayloadAction<AdvanceState>) => {
      state.status = action.payload.status;
      state.statusCode = action.payload.statusCode;
      state.message = action.payload.message;
      state.data = action.payload.data;
    },
  },
});

const { setAdvance } = advanceSlice.actions;

export const getAllAdvanceAsync = (): AppThunk => async (dispatch) => {
  const response = await callApi("GET", "/advance");
  await dispatch(setAdvance(response));
  // return response;
};

export const getAdvanceByIdAsync =
  (id: string): AppThunk =>
  async () => {
    const response = await callApi("GET", `/advance/${id}`);
    return response;
  };

type AddAdvanceDataType = {
  company: string;
  amount: number;
  chequeNo: string;
  bank: string;
  purpose: string;
  date: number;
  photo: string;
  pdf: string;
  note: string;
};

export const addAdvanceAsync =
  ({
    company,
    amount,
    chequeNo,
    bank,
    purpose,
    date,
    photo,
    pdf,
    note,
  }: AddAdvanceDataType): AppThunk =>
  async (dispatch) => {
    const reqData = {
      company,
      amount,
      chequeNo,
      bank,
      purpose,
      date,
      photo,
      pdf,
      note,
    };

    const response = await callApi("POST", "/advance", reqData);
    await dispatch(getAllAdvanceAsync());
    return response;
  };

type UpdateAdvanceDataType = {
  id: string;
  company: string;
  amount: number;
  chequeNo: string;
  bank: string;
  purpose: string;
  date: number;
  photo: string;
  pdf: string;
  note: string;
};

export const updateAdvanceAsync =
  ({
    id,
    company,
    amount,
    chequeNo,
    bank,
    purpose,
    date,
    photo,
    pdf,
    note,
  }: UpdateAdvanceDataType): AppThunk =>
  async (dispatch) => {
    const reqData = {
      company,
      amount,
      chequeNo,
      bank,
      purpose,
      date,
      photo,
      pdf,
      note,
    };
    const response = await callApi("PUT", `/advance/${id}`, reqData);
    await dispatch(getAllAdvanceAsync());
    return response;
  };

export const deleteAdvanceAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    const response = await callApi("DELETE", `/advance/${id}`);
    await dispatch(getAllAdvanceAsync());
    return response;
  };

export const getAdvanceData = (state: RootState) => state.advance;
export default advanceSlice.reducer;
