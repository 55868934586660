import React from "react";
import MainInput from "@/components/common/MainInput";
import MainButton from "../../../common/MainButton";
import { useAppDispatch } from "@/redux/hooks";
import { addOthersAsync } from "@/redux/slices/othersSlice";
import { notifyError, notifySuccess } from "@/components/common/MainNotify";
import { CircularProgress } from "@mui/material";

const OthersAddForm = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    note: "",
    photo: "",
    pdf: "",
  });
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const reqData = {
      ...formData,
    };

    // console.log("reqData", reqData);
    const res: any = await dispatch(addOthersAsync(reqData));

    if (res.status === "success") {
      setFormData({
        note: "",
        photo: "",
        pdf: "",
      });

      notifySuccess(res.message);
      setLoading(false);
    } else {
      notifyError(res.message);
      setLoading(false);
    }
  };
  return (
    <div className="AddForm" style={{ backgroundColor: "transparent" }}>
      <MainInput
        title="Note"
        name="note"
        placeholder="Type any note here"
        value={formData.note}
        setValue={handleOnChange}
        textarea
        required
      />
      <MainInput
        title="Image Link"
        name="photo"
        placeholder="Image Link"
        value={formData.photo}
        setValue={handleOnChange}
      />
      <MainInput
        title="PDF Link"
        name="pdf"
        placeholder="PDF Link"
        value={formData.pdf}
        setValue={handleOnChange}
      />

      {loading ? (
        <CircularProgress />
      ) : (
        <MainButton type="button" text="Submit" onClick={handleSubmit} />
      )}
    </div>
  );
};

export default OthersAddForm;
