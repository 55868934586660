interface MainButtonPops {
  type?: "submit" | "reset" | "button";
  text: string;
  onClick?: (e: any) => void;
  disabled?: boolean;
}

const MainButton = ({
  type = "button",
  text,
  onClick,
  disabled = false,
}: MainButtonPops) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className="main_button"
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      {text}
    </button>
  );
};

export default MainButton;
