import React from "react";
import MainInput from "@/components/common/MainInput";
import MainButton from "@/components/common/MainButton";
import { useAppDispatch } from "@/redux/hooks";
import { addCompanyAsync } from "@/redux/slices/companySlice";
import { notifyError, notifySuccess } from "@/components/common/MainNotify";
import { CircularProgress } from "@mui/material";

const CompanyAddForm = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    companyName: "",
    companyAddress: "",
    companyPhone: "",
    companyEmail: "",
    companyWebsite: "",
    companyLogo: "",
    companyDescription: "",
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const reqData = {
      ...formData,
    };

    // console.log("reqData: ", reqData);
    const res: any = await dispatch(addCompanyAsync(reqData));

    if (res.status === "success") {
      setFormData({
        companyName: "",
        companyAddress: "",
        companyPhone: "",
        companyEmail: "",
        companyWebsite: "",
        companyLogo: "",
        companyDescription: "",
      });

      notifySuccess(res.message);
      setLoading(false);
    } else {
      notifyError(res.message);
      setLoading(false);
    }
  };

  return (
    <div className="AddForm" style={{ backgroundColor: "transparent" }}>
      <MainInput
        title="Name"
        name="companyName"
        placeholder="Enter Company Name"
        value={formData.companyName}
        setValue={handleOnChange}
        required
      />
      <MainInput
        title="Address"
        name="companyAddress"
        placeholder="Enter Company Address"
        value={formData.companyAddress}
        setValue={handleOnChange}
      />
      <MainInput
        title="Phone"
        name="companyPhone"
        placeholder="Enter Company Phone No."
        value={formData.companyPhone}
        setValue={handleOnChange}
      />

      <MainInput
        type="email"
        title="Email"
        name="companyEmail"
        placeholder="Enter Email Address"
        value={formData.companyEmail}
        setValue={handleOnChange}
      />
      <MainInput
        title="Website"
        name="companyWebsite"
        placeholder="Enter Website Link"
        value={formData.companyWebsite}
        setValue={handleOnChange}
      />
      <MainInput
        title="Logo"
        name="companyLogo"
        placeholder="Enter Company Logo Link"
        value={formData.companyLogo}
        setValue={handleOnChange}
      />
      <MainInput
        title="Description"
        name="companyDescription"
        placeholder="Enter Company Description"
        value={formData.companyDescription}
        setValue={handleOnChange}
        textarea
      />

      {loading ? (
        <CircularProgress />
      ) : (
        <MainButton type="button" text="Submit" onClick={handleSubmit} />
      )}
    </div>
  );
};

export default CompanyAddForm;
