import React from "react";
import MainInput from "@/components/common/MainInput";
import MainButton from "@/components/common/MainButton";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { addBankAsync } from "@/redux/slices/bankSlice";
import { notifyError, notifySuccess } from "@/components/common/MainNotify";
import { CircularProgress } from "@mui/material";

const BankAddForm = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    bankName: "",
    bankLogo: "",
    bankDescription: "",
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const reqData = {
      ...formData,
    };

    // console.log("reqData: ", reqData);
    const res: any = await dispatch(addBankAsync(reqData));

    if (res.status === "success") {
      setFormData({
        bankName: "",
        bankLogo: "",
        bankDescription: "",
      });

      notifySuccess(res.message);
      setLoading(false);
    } else {
      notifyError(res.message);
      setLoading(false);
    }
  };

  return (
    <div className="AddForm" style={{ backgroundColor: "transparent" }}>
      <MainInput
        title="Name"
        name="bankName"
        placeholder="Enter Bank Name"
        value={formData.bankName}
        setValue={handleOnChange}
        required
      />
      <MainInput
        title="Logo"
        name="bankLogo"
        placeholder="Enter Bank Image"
        value={formData.bankLogo}
        setValue={handleOnChange}
      />
      <MainInput
        title="Description"
        name="bankDescription"
        placeholder="Enter Bank Description"
        value={formData.bankDescription}
        setValue={handleOnChange}
      />

{loading ? (
            <CircularProgress />
          ) : (
      <MainButton type="button" text="Submit" onClick={handleSubmit} />
          )}
    </div>
  );
};

export default BankAddForm;
