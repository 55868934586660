import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { callApi } from "../apiHelper";
import { RootState, AppThunk } from "../store";

interface OthersData {
  _id: string;
  note: string;
  photo: string;
  pdf: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface OthersState {
  status: "success" | "error" | "";
  statusCode: number;
  message: string;
  data: OthersData[];
}

const initialState: OthersState = {
  status: "",
  statusCode: 0,
  message: "",
  data: [],
};

export const OthersSlice = createSlice({
  name: "other",
  initialState,
  reducers: {
    setOthers: (state, action: PayloadAction<OthersState>) => {
      state.status = action.payload.status;
      state.statusCode = action.payload.statusCode;
      state.message = action.payload.message;
      state.data = action.payload.data;
    },
  },
});

const { setOthers } = OthersSlice.actions;

export const getAllOthersAsync = (): AppThunk => async (dispatch) => {
  const response = await callApi("GET", "/other");
  await dispatch(setOthers(response));
};

export const getOthersByIdAsync =
  (id: string): AppThunk =>
  async () => {
    const response = await callApi("GET", `/other/${id}`);
    return response;
  };

type AddOthersDataType = {
  note: string;
  photo: string;
  pdf: string;
};

export const addOthersAsync =
  ({ note, photo, pdf }: AddOthersDataType): AppThunk =>
  async (dispatch) => {
    const reqData = { note, photo, pdf };
    const response = await callApi("POST", "/other", reqData);
    await dispatch(getAllOthersAsync());
    return response;
  };

type UpdateOthersDataType = {
  id: string;
  note: string;
  photo: string;
  pdf: string;
};

export const updateOthersAsync =
  ({ id, note, photo, pdf }: UpdateOthersDataType): AppThunk =>
  async (dispatch) => {
    const reqData = { note, photo, pdf };
    const response = await callApi("PUT", `/other/${id}`, reqData);
    await dispatch(getAllOthersAsync());
    return response;
  };

export const deleteOthersAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    const response = await callApi("DELETE", `/other/${id}`);
    await dispatch(getAllOthersAsync());
    return response;
  };

export const getOthersData = (state: RootState) => state.others;
export default OthersSlice.reducer;
