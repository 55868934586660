import * as React from "react";
import { Link } from "react-router-dom";

const Copyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    // @ts-ignore
    <div style={styles.container} className="copyright__container">
      {/* <div style={styles.linkSection}>
        <Link to="mailto:arif@wind.app" style={styles.link}>
          arif@wind.app
        </Link>
      </div> */}

      <p style={styles.copyrightText}>
        Copyright © Designed & Developed by Wind 2022-{currentYear}
      </p>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    background: "#08071B",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0",
    marginTop: "50px",
  },
  linkSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // width: "450px",
    marginBottom: "25px",
  },
  link: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    textDecorationLine: "underline",
    color: "#1890FF",
  },
  copyrightText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#FFFFFF",
    margin: 0,
  },
};

export default Copyright;
