import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "@/styles/main-input.module.css";

interface SelectData {
  id: string;
  label: string;
}

interface MainSelectProps {
  title?: string;
  name: string;
  required?: boolean;
  data: SelectData[];
  setSelect: (e: any) => void;
}

export default function MainSelect({
  title,
  name,
  required,
  data,
  setSelect,
}: MainSelectProps) {
  return (
    <div className={styles.main_input_section}>
      {title && (
        <label htmlFor={name} className={styles.title}>
          <span className={styles.required}>{required && "* "}</span>
          {title}
        </label>
      )}
      <Autocomplete
        disablePortal
        id={name}
        options={data}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={{
          width: "100%",
          background: "#08071b",
          border: "1px solid #828282",
          borderRadius: "6px",
          color: "#fff",
          input: { color: "#fff" },
          svg: { color: "#fff" },
        }}
        renderInput={(params) => <TextField {...params} />}
        onChange={(event, values) => setSelect(values?.id || "")}
      />
    </div>
  );
}


