import Cookies from "js-cookie";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { callApi } from "../apiHelper";
import { RootState, AppThunk } from "../store";

interface DashboardState {
  status: "success" | "error" | "";
  statusCode: number;
  message: string;
  data: {
    totalBalance: number;
    totalCost: number;
    totalAdvance: number;
  };
}

const initialState: DashboardState = {
  status: "",
  statusCode: 0,
  message: "",
  data: {
    totalBalance: 0,
    totalCost: 0,
    totalAdvance: 0,
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setAmount: (state, action: PayloadAction<DashboardState>) => {
      state.status = action.payload.status;
      state.statusCode = action.payload.statusCode;
      state.message = action.payload.message;
      state.data = action.payload.data;
    },
  },
});

const { setAmount } = dashboardSlice.actions;

export const getAllAmountAsync = (): AppThunk => async (dispatch) => {
  const response = await callApi("GET", "/dashboard/amount");
  await dispatch(setAmount(response));

  if (response.statusCode === 401) {
    Cookies.remove("accessToken");
    window.location.href = "/";
  }
  // return response;
};

export const getDashboardData = (state: RootState) => state.dashboard;
export default dashboardSlice.reducer;
