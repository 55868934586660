import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { callApi } from "../apiHelper";
import { RootState, AppThunk } from "../store";
import { BankData } from "./bankSlice";
import { CompanyData } from "./companySlice";
import { PurposeData } from "./purposeSlice";

interface CostData {
  _id: string;
  company: CompanyData[];
  bank: BankData[];
  chequeNo: string;
  amount: number;
  purpose: PurposeData[];
  date: number;
  pdf: string;
  photo: string;
  note: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface CostState {
  status: "success" | "error" | "";
  statusCode: number;
  message: string;
  data: CostData[];
}

const initialState: CostState = {
  status: "",
  statusCode: 0,
  message: "",
  data: [],
};

export const CostSlice = createSlice({
  name: "cost",
  initialState,
  reducers: {
    setCost: (state, action: PayloadAction<CostState>) => {
      state.status = action.payload.status;
      state.statusCode = action.payload.statusCode;
      state.message = action.payload.message;
      state.data = action.payload.data;
    },
  },
});

const { setCost } = CostSlice.actions;

export const getAllCostAsync = (): AppThunk => async (dispatch) => {
  const response = await callApi("GET", "/cost");
  await dispatch(setCost(response));
};

export const getCostByIdAsync =
  (id: string): AppThunk =>
  async () => {
    const response = await callApi("GET", `/cost/${id}`);
    return response;
  };

type AddCostDataType = {
  company: string;
  bank: string;
  chequeNo: string;
  amount: number;
  purpose: string;
  date: number;
  pdf: string;
  photo: string;
  note: string;
};

export const addCostAsync =
  ({
    company,
    bank,
    chequeNo,
    amount,
    purpose,
    date,
    pdf,
    photo,
    note,
  }: AddCostDataType): AppThunk =>
  async (dispatch) => {
    const reqData = {
      company,
      bank,
      chequeNo,
      amount,
      purpose,
      date,
      pdf,
      photo,
      note,
    };

    const response = await callApi("POST", "/cost", reqData);
    await dispatch(getAllCostAsync());
    return response;
  };

type UpdateCostDataType = {
  id: string;
  company: string;
  bank: string;
  chequeNo: string;
  amount: number;
  purpose: string;
  date: number;
  pdf: string;
  photo: string;
  note: string;
};

export const updateCostAsync =
  ({
    id,
    company,
    amount,
    chequeNo,
    bank,
    purpose,
    date,
    photo,
    pdf,
    note,
  }: UpdateCostDataType): AppThunk =>
  async (dispatch) => {
    const reqData = {
      company,
      amount,
      chequeNo,
      bank,
      purpose,
      date,
      photo,
      pdf,
      note,
    };
    const response = await callApi("PUT", `/cost/${id}`, reqData);
    await dispatch(getAllCostAsync());
    return response;
  };

export const deleteCostAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    const response = await callApi("DELETE", `/cost/${id}`);
    await dispatch(getAllCostAsync());
    return response;
  };

export const getCostData = (state: RootState) => state.cost;
export default CostSlice.reducer;
