import { useEffect, useState } from "react";
import styles from "@/styles/auth.module.css";
import authBg from "@/assets/images/auth-BG.png";
// import logo from "@/assets/icons/logo.svg";
// import {
//   Link,
//   Navigate,
//   useNavigate,
//   redirect,
//   useLocation,
// } from "react-router-dom";
import MainInput from "@/components/common/MainInput";
import MainButton from "@/components/common/MainButton";
import { validateEmail } from "@/utils";
import { useAppDispatch } from "@/redux/hooks";
import {
  forgotPasswordRequestAsync,
  loginByEmailAsync,
  loginByUsernameAsync,
} from "@/redux/slices/authSlice";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";
import MainModal from "../common/MainModal";
import { notifyError, notifySuccess } from "../common/MainNotify";

const Login = () => {
  // const navigate = useNavigate();
  // let location = useLocation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [formData, setFormData] = useState({
    emailOrUsername: "",
    password: "",
  });

  const handleOnChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const isEmail = validateEmail(formData.emailOrUsername);
    const _emailOrUsername = formData.emailOrUsername.toLowerCase();

    const _formData = {
      username: isEmail ? "" : _emailOrUsername,
      email: isEmail ? _emailOrUsername : "",
      password: formData.password,
    };

    // console.log("Login formData", _formData);

    let res: any;

    if (isEmail) {
      res = await dispatch(loginByEmailAsync(_formData));
    } else {
      res = await dispatch(loginByUsernameAsync(_formData));
    }

    // console.log("Login res", res)

    if (res.status === "success") {
      // console.log("Login success", res);
      setError("");
      if (res.data.accessToken) {
        // console.log("res.data.accessToken", res.data.accessToken);
        await Cookies.set("accessToken", res.data.accessToken, { expires: 1 });
        window.location.href = "/dashboard";
        setLoading(false);
      } else {
        console.log("No accessToken");
        setLoading(false);
      }
      // navigate("/dashboard");
      // redirect("/dashboard");
    } else {
      console.log("Login error", res.message);
      setError(res.message);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log("accessToken", Cookies.get("accessToken"));
  // }, [loading]);

  return (
    <section className={`${styles.authSection} ${styles.loginSection}`}>
      <aside
        className={`${styles.w_50} ${styles.auth_leftSection} ${styles.login_leftSection}`}
        style={{
          backgroundImage: `url(${authBg})`,
        }}
      ></aside>
      <aside
        className={`${styles.w_50} ${styles.auth_rightSection} ${styles.login_rightSection}`}
      >
        {/* <img src={logo} alt="logo" className={styles.logo} /> */}
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <img
            src="/ahsan.jpeg"
            alt="Ahsan"
            style={{ height: "50px", width: "50px", borderRadius: "50%" }}
          />
          <h1 style={{ color: "white", fontSize: "25px" }}>Ahsan</h1>
        </div>
        <h1 className={styles.title}>Welcome Back</h1>
        <p className={styles.desc}>Welcome back! Please enter your details.</p>

        <form onSubmit={handleLogin} className={styles.form}>
          <MainInput
            type="text"
            title="Email or Username"
            name="emailOrUsername"
            placeholder="Email or Username"
            value={formData.emailOrUsername}
            setValue={handleOnChange}
            required
          />

          <MainInput
            type="password"
            title="Password"
            name="password"
            placeholder="Password"
            value={formData.password}
            setValue={handleOnChange}
            required
          />

          {/* <p className={styles.forgot_password_section}>
            <Link to="#" className={styles.forgot_password_text}>
              Forgot password?
            </Link>
          </p> */}

          <p className={styles.forgot_password_section}>
            <button
              type="button"
              onClick={() => setOpenForgotPasswordModal(true)}
              className={styles.forgot_password_text}
              style={{ background: "none", border: "none" }}
            >
              Forgot password?
            </button>
          </p>

          <p className={styles.errorText}>{error}</p>

          {loading ? (
            <CircularProgress />
          ) : (
            <MainButton
              type="submit"
              text="Sign-In"
              disabled={
                formData.emailOrUsername === "" || formData.password === ""
              }
            />
          )}
        </form>

        {/* <p className={styles.redirect_link}>
          Don’t have an account?
          <Link
            to="/registration"
            style={{ color: "#3F94F7", textDecoration: "none" }}
          >
            {" "}
            Sign Up
          </Link>
        </p> */}

        <MainModal
          title="Forgot Password"
          open={openForgotPasswordModal}
          setOpen={setOpenForgotPasswordModal}
        >
          <ForgotPassword />
        </MainModal>
      </aside>
    </section>
  );
  // }
};

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const res: any = await dispatch(forgotPasswordRequestAsync(email));

    if (res.status === "success") {
      notifySuccess(res.message);
      setLoading(false);
    } else {
      notifyError(res.message);
      setLoading(false);
    }
  };

  return (
    <div style={{ backgroundColor: "transparent" }}>
      <MainInput
        title="Email"
        name="email"
        placeholder="Enter your Email"
        value={email}
        setValue={(e) => setEmail(e.target.value)}
      />

      {loading ? (
        <CircularProgress />
      ) : (
        <MainButton type="button" text="Submit" onClick={handleSubmit} />
      )}
    </div>
  );
};

export default Login;
