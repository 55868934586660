import React from "react";
import Dashboard from "@/components/dashboard";
import MainTable from "@/components/common/MainTable";
import PurposeAddForm from "@/components/dashboard/menu/Purpose/PurposeAddForm";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  deletePurposeAsync,
  getAllPurposeAsync,
  getPurposeData,
} from "@/redux/slices/purposeSlice";
import { formatDate } from "@/utils";

interface Data {
  _id: string;
  no: number;
  purposeTitle: string;
  createdAt: string;
  updatedAt: string;
}

// interface HeadCell {
//   id: string;
//   label: string;
//   name: string;
//   numeric: boolean;
//   disablePadding: boolean;
// }

const headCells = [
  {
    id: "0",
    label: "#",
    name: "no",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "1",
    label: "Purpose",
    name: "purposeTitle",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "2",
    label: "Create Date",
    name: "createdAt",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "3",
    label: "Update Date",
    name: "updatedAt",
    numeric: false,
    disablePadding: false,
  },
];

const Purpose = () => {
  const dispatch = useAppDispatch();
  const { data }: any = useAppSelector(getPurposeData);
  const [rows, setRows] = React.useState<Data[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllPurposeAsync());
    };
    fetchData();
  }, [dispatch]);

  React.useEffect(() => {
    if (data && data.length > 0) {
      const newData = data.map((item: any, i: number) => {
        const { _id, purposeTitle, createdAt, updatedAt } = item;
        return {
          _id,
          no: i + 1,
          purposeTitle,
          createdAt: formatDate(createdAt),
          updatedAt: formatDate(updatedAt),
        };
      });
      setRows(newData);
    } else {
      setRows([]);
    }
  }, [data]);

  return (
    <Dashboard>
      <MainTable
        tableName="Purpose List"
        headCells={headCells}
        rows={rows}
        modalTitle="Purpose"
        form={PurposeAddForm}
        deleteFunc={deletePurposeAsync}
      />
    </Dashboard>
  );
};

export default Purpose;
