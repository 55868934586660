import * as React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Switch,
  Popover,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "@/styles/dashboard-header.module.css";
import MenuIcon from "@mui/icons-material/Menu";
// import searchIcon from "@/assets/icons/search.svg";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getAuthData } from "@/redux/slices/authSlice";
import Cookies from "js-cookie";
import MainModal from "@/components/common/MainModal";
import MainInput from "@/components/common/MainInput";
import { CircularProgress } from "@mui/material";
import { notifyError, notifySuccess } from "@/components/common/MainNotify";
import MainButton from "../common/MainButton";
import {
  changePasswordAsync,
  getProfileAsync,
  getProfileData,
  updateProfileAsync,
} from "@/redux/slices/profileSlice";

interface HeaderProps {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

const Header = ({ drawerWidth, handleDrawerToggle }: HeaderProps) => {
  // const authData = useAppSelector(getAuthData);
  const profileData = useAppSelector(getProfileData);
  // const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEditProfileModal, setOpenEditProfileModal] = React.useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] =
    React.useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove("accessToken");
    window.location.href = "/";
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        background: "#0F0E20",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ height: 80 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <section className={styles.header}>
          <aside></aside>
          {/* <aside className={styles.searchInputSection}>
            <img src={searchIcon} alt="searchIcon" />
            <input
              type="text"
              name="search"
              placeholder="Search Here..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </aside> */}

          <aside className={styles.profile}>
            <MaterialUISwitch sx={{ m: 1 }} defaultChecked />

            <div
              aria-describedby={id}
              // variant="contained"
              onClick={handleClick}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Avatar alt="" src="" sx={{ width: 32, height: 32 }} />
              <h3 style={{ marginLeft: "5px" }}>
                {profileData.data.username || "ahsan"}
              </h3>
            </div>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box sx={{ width: "150px", p: "15px 15px", bgcolor: "#3f94f7" }}>
                <button
                  type="button"
                  onClick={() => setOpenEditProfileModal(true)}
                  className={styles.btn}
                >
                  Edit Profile
                </button>
                <button
                  type="button"
                  onClick={() => setOpenChangePasswordModal(true)}
                  className={styles.btn}
                >
                  Change Password
                </button>
                <button
                  type="button"
                  onClick={handleLogout}
                  className={styles.btn}
                >
                  Log out
                </button>
              </Box>
            </Popover>
          </aside>
        </section>

        <MainModal
          title="Edit Profile"
          open={openEditProfileModal}
          setOpen={setOpenEditProfileModal}
        >
          <EditProfle />
        </MainModal>

        <MainModal
          title="Change Password"
          open={openChangePasswordModal}
          setOpen={setOpenChangePasswordModal}
        >
          <ChangePassword />
        </MainModal>
      </Toolbar>
    </AppBar>
  );
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const EditProfle = () => {
  const dispatch = useAppDispatch();
  const profileData = useAppSelector(getProfileData);
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: "",
    username: "",
    email: "",
  });

  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(getProfileAsync());
    };
    fetchData();
  }, [dispatch]);

  React.useEffect(() => {
    if (profileData?.data?.email?.length > 0) {
      setFormData({
        name: profileData?.data?.name,
        username: profileData?.data?.username,
        email: profileData?.data?.email,
      });
    }
  }, [profileData]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const reqData = {
      ...formData,
    };

    // ready for api call
    // console.log("reqData: ", reqData);
    const res: any = await dispatch(updateProfileAsync(reqData));

    if (res.status === "success") {
      setFormData({
        name: "",
        username: "",
        email: "",
      });

      notifySuccess(res.message);
      setLoading(false);
    } else {
      notifyError(res.message);
      setLoading(false);
    }
  };

  return (
    <div className="AddForm" style={{ backgroundColor: "transparent" }}>
      <MainInput
        title="Name"
        name="name"
        placeholder="Enter your Name"
        value={formData.name}
        setValue={handleOnChange}
      />
      <MainInput
        title="UserName"
        name="username"
        placeholder="Enter your UserName"
        value={formData.username}
        setValue={handleOnChange}
      />
      <MainInput
        title="Email"
        name="email"
        placeholder="Enter your email"
        value={formData.email}
        setValue={handleOnChange}
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <MainButton type="button" text="Submit" onClick={handleSubmit} />
      )}
    </div>
  );
};

const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    oldPassword: "",
    password: "",
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const reqData = {
      ...formData,
    };

    // ready for api call
    // console.log("reqData: ", reqData);
    const res: any = await dispatch(changePasswordAsync(reqData));

    if (res.status === "success") {
      setFormData({
        oldPassword: "",
        password: "",
      });

      notifySuccess(res.message);
      setLoading(false);
    } else {
      notifyError(res.message);
      setLoading(false);
    }
  };

  return (
    <div className="AddForm" style={{ backgroundColor: "transparent" }}>
      <MainInput
        title="Old Password"
        name="oldPassword"
        placeholder="Enter Old Password"
        value={formData.oldPassword}
        setValue={handleOnChange}
      />
      <MainInput
        title="New Password"
        name="password"
        placeholder="Enter your New Password"
        value={formData.password}
        setValue={handleOnChange}
      />

      {loading ? (
        <CircularProgress />
      ) : (
        <MainButton type="button" text="Submit" onClick={handleSubmit} />
      )}
    </div>
  );
};

export default Header;
