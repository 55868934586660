import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { callApi } from "../apiHelper";
import { RootState, AppThunk } from "../store";

export interface CompanyData {
  _id: string;
  companyName: string;
  companyAddress: string;
  companyPhone: string;
  companyEmail: string;
  companyWebsite: string;
  companyLogo: string;
  companyDescription: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface CompanyState {
  status: "success" | "error" | "";
  statusCode: number;
  message: string;
  data: CompanyData[];
}

const initialState: CompanyState = {
  status: "",
  statusCode: 0,
  message: "",
  data: [],
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<CompanyState>) => {
      state.status = action.payload.status;
      state.statusCode = action.payload.statusCode;
      state.message = action.payload.message;
      state.data = action.payload.data;
    },
  },
});

const { setCompany } = companySlice.actions;

export const getAllCompanyAsync = (): AppThunk => async (dispatch) => {
  const response = await callApi("GET", "/company");
  await dispatch(setCompany(response));
  // return response;
};

export const getCompanyByIdAsync =
  (id: string): AppThunk =>
  async () => {
    const response = await callApi("GET", `/company/${id}`);
    return response;
  };

type AddCompanyDataType = {
  companyName: string;
  companyAddress: string;
  companyPhone: string;
  companyEmail: string;
  companyWebsite: string;
  companyLogo: string;
  companyDescription: string;
};

export const addCompanyAsync =
  ({
    companyName,
    companyAddress,
    companyPhone,
    companyEmail,
    companyWebsite,
    companyLogo,
    companyDescription,
  }: AddCompanyDataType): AppThunk =>
  async (dispatch) => {
    const reqData = {
      companyName,
      companyAddress,
      companyPhone,
      companyEmail,
      companyWebsite,
      companyLogo,
      companyDescription,
    };
    const response = await callApi("POST", "/company", reqData);
    await dispatch(getAllCompanyAsync());
    return response;
  };

type UpdateCompanyDataType = {
  id: string;
  companyName: string;
  companyAddress: string;
  companyPhone: string;
  companyEmail: string;
  companyWebsite: string;
  companyLogo: string;
  companyDescription: string;
};

export const updateCompanyAsync =
  ({
    id,
    companyName,
    companyAddress,
    companyPhone,
    companyEmail,
    companyWebsite,
    companyLogo,
    companyDescription,
  }: UpdateCompanyDataType): AppThunk =>
  async (dispatch) => {
    const reqData = {
      companyName,
      companyAddress,
      companyPhone,
      companyEmail,
      companyWebsite,
      companyLogo,
      companyDescription,
    };
    const response = await callApi("PUT", `/company/${id}`, reqData);
    await dispatch(getAllCompanyAsync());
    return response;
  };

export const deleteCompanyAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    const response = await callApi("DELETE", `/company/${id}`);
    await dispatch(getAllCompanyAsync());
    return response;
  };

export const getCompanyData = (state: RootState) => state.company;
export default companySlice.reducer;
